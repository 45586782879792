let promise = null;
let cache = null;

export default async function fetchTokenData() {
  if (cache) return cache;
  if (promise) return promise;

  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  };

  return promise = fetch('/actions/users/session-info', { headers })
    .then(response => response.json())
    .then(({ csrfTokenName: tokenName, csrfTokenValue: tokenValue }) => {
      return cache = { tokenName, tokenValue };
    });
}
