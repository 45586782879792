/**
 * formatted version of the third-party livechat initialisation widget
 */
const WIDGET = (function (n, t, c) {
  function i(n) {
    return e._h ? e._h.apply(null, n) : e._q.push(n)
  }

  const e = {
    _q: [], _h: null, _v: "2.0", on: function () {
      i(["on", c.call(arguments)])
    }, once: function () {
      i(["once", c.call(arguments)])
    }, off: function () {
      i(["off", c.call(arguments)])
    }, get: function () {
      if (!e._h) throw new Error("[LiveChatWidget] You can't use getters before load.");
      return i(["get", c.call(arguments)])
    }, call: function () {
      i(["call", c.call(arguments)])
    }, init: function () {
      var n = t.createElement("script");
      n.async = !0, n.type = "text/javascript", n.src = "https://cdn.livechatinc.com/tracking.js", t.head.appendChild(n)
    }
  };
  n.LiveChatWidget = n.LiveChatWidget || e
  return e;
}(window, document, [].slice));

/**
 * @param {HTMLElement} livechatWrapperEl
 */
const initAndMaximize = (livechatWrapperEl) => {
  livechatWrapperEl.remove();
  WIDGET.init();
  WIDGET.call('maximize');
};

/**
 * @param {HTMLElement} livechatWrapperEl
 */
export default function init(livechatWrapperEl = document.querySelector('.c-livechat')) {
  if (!livechatWrapperEl) return

  livechatWrapperEl
    .querySelector('.c-livechat__button')
    .addEventListener('click', () => {
      
      // If correct consent has not been provded
      if (!window.Cookiebot.consent.statistics || !window.Cookiebot.consent.preferences) {

        // Create an event listener for the consent update
        window.addEventListener('CookiebotOnAccept', function () {
          // Check the right consent given
          if (window.Cookiebot.consent.statistics && window.Cookiebot.consent.preferences) {
            initAndMaximize(livechatWrapperEl);
          }
        }, false);

        // Trigger the cookiebot modal to reappear
        window.Cookiebot.renew();
      
        return;
      }

      // If correct consent has been provided 
      initAndMaximize(livechatWrapperEl);
      
    });
}
