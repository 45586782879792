import fetchTokenData from '@modules/csrf';

// @todo check fbclid query param exists in JS and create cookie if so

// Check either _fbc of fbclid cookies exist
const fbcCookieExists = () => document.cookie.indexOf('_fbc=') > -1 || document.cookie.indexOf('fbclid=') > -1;

const fbclidParamExists = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.has('fbclid');
};

const setCookie = (name, value, days = 7, path = '/') => {
  const expires = new Date(Date.now() + days * 864e5).toUTCString();
  const fbclidCookie = `${name}=${encodeURIComponent(value)}; expires=${expires}; path=${path}`;
  document.cookie = fbclidCookie;
};

const createFbclidCookie = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const fbclidParamValue = urlParams.get('fbclid');
  const cookieValue = {
    fbclid: fbclidParamValue,
    time: (new Date()).getTime(),
  };
  setCookie('fbclid', JSON.stringify(cookieValue), 365);
};

// Trigger the server side's PageView event
const serverSidePageView = async (csrfTokenName, csrfTokenValue) => {
  const { eventIdSuffix } = window;
  const eventId = `PageView-${eventIdSuffix}`;
  let body = {
    eventId,
    [csrfTokenName]: csrfTokenValue,
  };
  body = JSON.stringify(body);
  fetch('/actions/facebook-tracker-module/facebook/send-page-view-event', {
    method: 'GET',
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'Content-Type': 'application/json',
    },
    body,
  });
};

// The default function which will trigger the PageView event
export default async function init() {
  // if fbclid query string param exists, create a fbclid cookie
  if (fbclidParamExists()) {
    createFbclidCookie();
  }

  // If _fbc or fbclid cookies do not exist do not continue
  if (!fbcCookieExists()) {
    return;
  }

  const { tokenName, tokenValue } = await fetchTokenData();
  serverSidePageView(tokenName, tokenValue);
}
